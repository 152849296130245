// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-zh-cn-js": () => import("./../../../src/pages/about.zh-cn.js" /* webpackChunkName: "component---src-pages-about-zh-cn-js" */),
  "component---src-pages-about-zh-tw-js": () => import("./../../../src/pages/about.zh-tw.js" /* webpackChunkName: "component---src-pages-about-zh-tw-js" */),
  "component---src-pages-certificates-js": () => import("./../../../src/pages/certificates.js" /* webpackChunkName: "component---src-pages-certificates-js" */),
  "component---src-pages-certificates-zh-cn-js": () => import("./../../../src/pages/certificates.zh-cn.js" /* webpackChunkName: "component---src-pages-certificates-zh-cn-js" */),
  "component---src-pages-certificates-zh-tw-js": () => import("./../../../src/pages/certificates.zh-tw.js" /* webpackChunkName: "component---src-pages-certificates-zh-tw-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-zh-cn-js": () => import("./../../../src/pages/contact.zh-cn.js" /* webpackChunkName: "component---src-pages-contact-zh-cn-js" */),
  "component---src-pages-contact-zh-tw-js": () => import("./../../../src/pages/contact.zh-tw.js" /* webpackChunkName: "component---src-pages-contact-zh-tw-js" */),
  "component---src-pages-group-structure-js": () => import("./../../../src/pages/group-structure.js" /* webpackChunkName: "component---src-pages-group-structure-js" */),
  "component---src-pages-group-structure-zh-cn-js": () => import("./../../../src/pages/group-structure.zh-cn.js" /* webpackChunkName: "component---src-pages-group-structure-zh-cn-js" */),
  "component---src-pages-group-structure-zh-tw-js": () => import("./../../../src/pages/group-structure.zh-tw.js" /* webpackChunkName: "component---src-pages-group-structure-zh-tw-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-zh-cn-js": () => import("./../../../src/pages/index.zh-cn.js" /* webpackChunkName: "component---src-pages-index-zh-cn-js" */),
  "component---src-pages-index-zh-tw-js": () => import("./../../../src/pages/index.zh-tw.js" /* webpackChunkName: "component---src-pages-index-zh-tw-js" */),
  "component---src-pages-machinery-die-and-tooling-workshop-js": () => import("./../../../src/pages/machinery/die-and-tooling-workshop.js" /* webpackChunkName: "component---src-pages-machinery-die-and-tooling-workshop-js" */),
  "component---src-pages-machinery-die-and-tooling-workshop-zh-cn-js": () => import("./../../../src/pages/machinery/die-and-tooling-workshop.zh-cn.js" /* webpackChunkName: "component---src-pages-machinery-die-and-tooling-workshop-zh-cn-js" */),
  "component---src-pages-machinery-die-and-tooling-workshop-zh-tw-js": () => import("./../../../src/pages/machinery/die-and-tooling-workshop.zh-tw.js" /* webpackChunkName: "component---src-pages-machinery-die-and-tooling-workshop-zh-tw-js" */),
  "component---src-pages-machinery-multi-slide-bending-machine-js": () => import("./../../../src/pages/machinery/multi-slide-bending-machine.js" /* webpackChunkName: "component---src-pages-machinery-multi-slide-bending-machine-js" */),
  "component---src-pages-machinery-multi-slide-bending-machine-zh-cn-js": () => import("./../../../src/pages/machinery/multi-slide-bending-machine.zh-cn.js" /* webpackChunkName: "component---src-pages-machinery-multi-slide-bending-machine-zh-cn-js" */),
  "component---src-pages-machinery-multi-slide-bending-machine-zh-tw-js": () => import("./../../../src/pages/machinery/multi-slide-bending-machine.zh-tw.js" /* webpackChunkName: "component---src-pages-machinery-multi-slide-bending-machine-zh-tw-js" */),
  "component---src-pages-machinery-progressive-presses-js": () => import("./../../../src/pages/machinery/progressive-presses.js" /* webpackChunkName: "component---src-pages-machinery-progressive-presses-js" */),
  "component---src-pages-machinery-progressive-presses-zh-cn-js": () => import("./../../../src/pages/machinery/progressive-presses.zh-cn.js" /* webpackChunkName: "component---src-pages-machinery-progressive-presses-zh-cn-js" */),
  "component---src-pages-machinery-progressive-presses-zh-tw-js": () => import("./../../../src/pages/machinery/progressive-presses.zh-tw.js" /* webpackChunkName: "component---src-pages-machinery-progressive-presses-zh-tw-js" */),
  "component---src-pages-machinery-secondary-processing-js": () => import("./../../../src/pages/machinery/secondary-processing.js" /* webpackChunkName: "component---src-pages-machinery-secondary-processing-js" */),
  "component---src-pages-machinery-secondary-processing-zh-cn-js": () => import("./../../../src/pages/machinery/secondary-processing.zh-cn.js" /* webpackChunkName: "component---src-pages-machinery-secondary-processing-zh-cn-js" */),
  "component---src-pages-machinery-secondary-processing-zh-tw-js": () => import("./../../../src/pages/machinery/secondary-processing.zh-tw.js" /* webpackChunkName: "component---src-pages-machinery-secondary-processing-zh-tw-js" */),
  "component---src-pages-machinery-single-operation-and-hydraulic-presses-js": () => import("./../../../src/pages/machinery/single-operation-and-hydraulic-presses.js" /* webpackChunkName: "component---src-pages-machinery-single-operation-and-hydraulic-presses-js" */),
  "component---src-pages-machinery-single-operation-and-hydraulic-presses-zh-cn-js": () => import("./../../../src/pages/machinery/single-operation-and-hydraulic-presses.zh-cn.js" /* webpackChunkName: "component---src-pages-machinery-single-operation-and-hydraulic-presses-zh-cn-js" */),
  "component---src-pages-machinery-single-operation-and-hydraulic-presses-zh-tw-js": () => import("./../../../src/pages/machinery/single-operation-and-hydraulic-presses.zh-tw.js" /* webpackChunkName: "component---src-pages-machinery-single-operation-and-hydraulic-presses-zh-tw-js" */),
  "component---src-pages-machinery-transfer-presses-js": () => import("./../../../src/pages/machinery/transfer-presses.js" /* webpackChunkName: "component---src-pages-machinery-transfer-presses-js" */),
  "component---src-pages-machinery-transfer-presses-zh-cn-js": () => import("./../../../src/pages/machinery/transfer-presses.zh-cn.js" /* webpackChunkName: "component---src-pages-machinery-transfer-presses-zh-cn-js" */),
  "component---src-pages-machinery-transfer-presses-zh-tw-js": () => import("./../../../src/pages/machinery/transfer-presses.zh-tw.js" /* webpackChunkName: "component---src-pages-machinery-transfer-presses-zh-tw-js" */),
  "component---src-pages-products-die-and-tooling-parts-js": () => import("./../../../src/pages/products/die-and-tooling-parts.js" /* webpackChunkName: "component---src-pages-products-die-and-tooling-parts-js" */),
  "component---src-pages-products-die-and-tooling-parts-zh-cn-js": () => import("./../../../src/pages/products/die-and-tooling-parts.zh-cn.js" /* webpackChunkName: "component---src-pages-products-die-and-tooling-parts-zh-cn-js" */),
  "component---src-pages-products-die-and-tooling-parts-zh-tw-js": () => import("./../../../src/pages/products/die-and-tooling-parts.zh-tw.js" /* webpackChunkName: "component---src-pages-products-die-and-tooling-parts-zh-tw-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-index-zh-cn-js": () => import("./../../../src/pages/products/index.zh-cn.js" /* webpackChunkName: "component---src-pages-products-index-zh-cn-js" */),
  "component---src-pages-products-index-zh-tw-js": () => import("./../../../src/pages/products/index.zh-tw.js" /* webpackChunkName: "component---src-pages-products-index-zh-tw-js" */),
  "component---src-pages-products-multi-slide-bending-parts-js": () => import("./../../../src/pages/products/multi-slide-bending-parts.js" /* webpackChunkName: "component---src-pages-products-multi-slide-bending-parts-js" */),
  "component---src-pages-products-multi-slide-bending-parts-zh-cn-js": () => import("./../../../src/pages/products/multi-slide-bending-parts.zh-cn.js" /* webpackChunkName: "component---src-pages-products-multi-slide-bending-parts-zh-cn-js" */),
  "component---src-pages-products-multi-slide-bending-parts-zh-tw-js": () => import("./../../../src/pages/products/multi-slide-bending-parts.zh-tw.js" /* webpackChunkName: "component---src-pages-products-multi-slide-bending-parts-zh-tw-js" */),
  "component---src-pages-products-nc-machining-parts-js": () => import("./../../../src/pages/products/nc-machining-parts.js" /* webpackChunkName: "component---src-pages-products-nc-machining-parts-js" */),
  "component---src-pages-products-nc-machining-parts-zh-cn-js": () => import("./../../../src/pages/products/nc-machining-parts.zh-cn.js" /* webpackChunkName: "component---src-pages-products-nc-machining-parts-zh-cn-js" */),
  "component---src-pages-products-nc-machining-parts-zh-tw-js": () => import("./../../../src/pages/products/nc-machining-parts.zh-tw.js" /* webpackChunkName: "component---src-pages-products-nc-machining-parts-zh-tw-js" */),
  "component---src-pages-products-polished-and-coated-products-js": () => import("./../../../src/pages/products/polished-and-coated-products.js" /* webpackChunkName: "component---src-pages-products-polished-and-coated-products-js" */),
  "component---src-pages-products-polished-and-coated-products-zh-cn-js": () => import("./../../../src/pages/products/polished-and-coated-products.zh-cn.js" /* webpackChunkName: "component---src-pages-products-polished-and-coated-products-zh-cn-js" */),
  "component---src-pages-products-polished-and-coated-products-zh-tw-js": () => import("./../../../src/pages/products/polished-and-coated-products.zh-tw.js" /* webpackChunkName: "component---src-pages-products-polished-and-coated-products-zh-tw-js" */),
  "component---src-pages-products-progressive-parts-js": () => import("./../../../src/pages/products/progressive-parts.js" /* webpackChunkName: "component---src-pages-products-progressive-parts-js" */),
  "component---src-pages-products-progressive-parts-zh-cn-js": () => import("./../../../src/pages/products/progressive-parts.zh-cn.js" /* webpackChunkName: "component---src-pages-products-progressive-parts-zh-cn-js" */),
  "component---src-pages-products-progressive-parts-zh-tw-js": () => import("./../../../src/pages/products/progressive-parts.zh-tw.js" /* webpackChunkName: "component---src-pages-products-progressive-parts-zh-tw-js" */),
  "component---src-pages-products-single-operation-and-hydraulic-pressing-parts-js": () => import("./../../../src/pages/products/single-operation-and-hydraulic-pressing-parts.js" /* webpackChunkName: "component---src-pages-products-single-operation-and-hydraulic-pressing-parts-js" */),
  "component---src-pages-products-single-operation-and-hydraulic-pressing-parts-zh-cn-js": () => import("./../../../src/pages/products/single-operation-and-hydraulic-pressing-parts.zh-cn.js" /* webpackChunkName: "component---src-pages-products-single-operation-and-hydraulic-pressing-parts-zh-cn-js" */),
  "component---src-pages-products-single-operation-and-hydraulic-pressing-parts-zh-tw-js": () => import("./../../../src/pages/products/single-operation-and-hydraulic-pressing-parts.zh-tw.js" /* webpackChunkName: "component---src-pages-products-single-operation-and-hydraulic-pressing-parts-zh-tw-js" */),
  "component---src-pages-products-transfer-parts-js": () => import("./../../../src/pages/products/transfer-parts.js" /* webpackChunkName: "component---src-pages-products-transfer-parts-js" */),
  "component---src-pages-products-transfer-parts-zh-cn-js": () => import("./../../../src/pages/products/transfer-parts.zh-cn.js" /* webpackChunkName: "component---src-pages-products-transfer-parts-zh-cn-js" */),
  "component---src-pages-products-transfer-parts-zh-tw-js": () => import("./../../../src/pages/products/transfer-parts.zh-tw.js" /* webpackChunkName: "component---src-pages-products-transfer-parts-zh-tw-js" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */),
  "component---src-pages-quality-zh-cn-js": () => import("./../../../src/pages/quality.zh-cn.js" /* webpackChunkName: "component---src-pages-quality-zh-cn-js" */),
  "component---src-pages-quality-zh-tw-js": () => import("./../../../src/pages/quality.zh-tw.js" /* webpackChunkName: "component---src-pages-quality-zh-tw-js" */)
}

